<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 16" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 16H0.571429L0 15.4286V0H1.14286V14.8571H16V16ZM2.28571 4V13.1429L2.85714 13.7143H5.14286L5.71429 13.1429V4L5.14286 3.42857H2.85714L2.28571 4ZM11.4286 13.1429V1.71429L12 1.14286H14.2857L14.8571 1.71429V13.1429L14.2857 13.7143H12L11.4286 13.1429ZM6.85714 6.28571V13.1429L7.42857 13.7143H9.71429L10.2857 13.1429V6.28571L9.71429 5.71429H7.42857L6.85714 6.28571Z"
    :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 16
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
